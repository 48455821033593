<template lang="">
  <div class="container">
    <Banner :title="news"></Banner>
    <div class="new_container wei-container ">
      <div class="new " v-if="newsList">
        <div
          class="new_item flex-row-top"
          v-for="(item, index) in newsList"
          :key="index"
        >
          <div class="img-box pointer" @click="toDetail(item)">
            <img :src="require('../../assets/images/com_img1.jpg')" alt="" />
          </div>
          <div class="info flex1">
            <div class="title hover" @click="toDetail(item)">{{ item.title }}</div>
            <div class="summary ellipsis-3 hover" @click="toDetail(item)">
              {{ item.synopsis }}{{ item.synopsis }}
            </div>
            <div class="time">{{
              item.createdTime && item.createdTime.split(" ")[0]
            }}</div>
            <div class="button pointer" @click="toDetail(item)">{{ news.lookText }}</div>
          </div>
        </div>
      </div>
    </div>
    <pagination
      class="pagination"
      v-show="total > 0"
      :total="total"
      :page.sync="params.pageNum"
      :limit.sync="params.pageSize"
      :autoScroll="true"
      @pagination="getQueryArticleList"
    />
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { queryArticleList } from "@/api/index";
import Pagination from "@/components/Pagination/index";
import Banner from "@/components/Banner";
import { scrollTo } from '@/utils/scroll-to'
export default {
  components: { Pagination, Banner },
  data() {
    return {
      news: {},
      newsList: [],
      total: 1,
      params: {
        pageNum: 1,
        pageSize: 10,
      },
    };
  },
  created() {
    this.news = this.$t("news");
    this.getQueryArticleList();
    console.log('created')
  },
  activated() {
    console.log('activage')
  },
  mounted() {},
  methods: {
    getQueryArticleList() {
      queryArticleList(this.params).then((res) => {
        this.newsList = res.data.records;
        this.total = res.data.total;
      });
    },
    toDetail(item){
      this.$router.push({
        path:'/news_detail?id='+item.id
      })
       scrollTo(0,800)
    }
  },
};
</script>
<style lang="scss" scoped>
.container {
  padding: 0px 0 200px 0;
  .new_container {
    text-align: left;
    .new_item {
      background: #fff;
      padding: 64px;
      margin-top: 60px;
      .img-box {
        width: 480px;
        height: 270px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .info {
        padding-left: 60px;
        margin-top: 30px;
        .title {
          font-size: 24px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        .summary {
          font-size: 14px;
          line-height: 1.5;
          margin-bottom: 18px;
          height: 64px;
        }
        .time {
          color: #aaaaaa;
        }
        .button {
          float: right;
          height: 40px;
          padding: 0 32px;
          line-height: 40px;
          text-align: center;
          background: #41cfad;
          color: #ffffff;
          margin-top: 50px;
          &:hover {
            background: rgba($color: #41cfad, $alpha: 0.8);
          }
        }
      }
    }
  }
  .pagination {
    margin-top: 80px;
  }
}
</style>
